<template>
  <div class="bg-yellow-500 py-1 shadow left-0 right-0 top-0 w-full">
    <div
      class="py-1 text-sm border-b-[2px] border-black w-full"
      v-if="filterHotnews()"
    >
      <Vue3Marquee :duration="30"><span v-html="hotnews"></span></Vue3Marquee>
    </div>
    <!-- {{ news }} -->
    <div class="max-w-screen-2xl mx-auto grid grid-cols-12 gap-1 lg:px-2">
      <div
        class="col-span-12 md:col-span-12 lg:col-span-2 xl:col-span-2 py-2 border-b-[2px] border-black lg:border-transparent"
      >
        <img
          width="100"
          class="m-auto lg:mx-4"
          src="~assets/images/logo/dlto.svg"
          alt="deelotto"
        />
        <!-- <p
          class="w-full text-xl font-lg font-semibold text-green text-center lg-text-left p-0 m-0 ml-0"
        >
          lotto
        </p> -->
      </div>
      <div
        class="col-span-12 md:col-span-12 lg:col-span-8 xl:col-span-8 hidden lg:flex justify-center items-center px-0 mx-0"
      >
        <NuxtLink
          activeClass="font-semibold text-green-800"
          to="/games"
          class="mx-2 flex lg:flex-col xl:flex-row items-center"
        >
          <span class="">
            <svg
              class="pr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12v8q0 .825-.588 1.413T20 22h-8Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 .575.075 1.125T4.3 14.2L8 10.5l3.3 2.775L14.575 10H13V8h5v5h-2v-1.575L11.4 16l-3.275-2.8l-2.95 2.95q1.05 1.725 2.838 2.788T12 20Zm7.5.5q.425 0 .713-.288t.287-.712q0-.425-.288-.713T19.5 18.5q-.425 0-.713.288t-.287.712q0 .425.288.713t.712.287ZM11.375 12Z"
              />
            </svg>
          </span>
          แทงหวย
        </NuxtLink>
        <NuxtLink
          activeClass="font-semibold text-green-800"
          to="/betlist"
          class="mx-2 flex lg:flex-col xl:flex-row items-center"
        >
          <span class="">
            <svg
              class="pr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12.5 9v1q0 .425.288.713T13.5 11q.425 0 .713-.288T14.5 10V9h1q.425 0 .713-.288T16.5 8q0-.425-.288-.713T15.5 7h-1V6q0-.425-.288-.713T13.5 5q-.425 0-.713.288T12.5 6v1h-1q-.425 0-.713.288T10.5 8q0 .425.288.713T11.5 9h1Zm-1 6h4q.425 0 .713-.288T16.5 14q0-.425-.288-.713T15.5 13h-4q-.425 0-.713.288T10.5 14q0 .425.288.713T11.5 15ZM8 19q-.825 0-1.413-.588T6 17V3q0-.825.588-1.413T8 1h6.175q.4 0 .763.15t.637.425l4.85 4.85q.275.275.425.638t.15.762V17q0 .825-.588 1.413T19 19H8Zm0-2h11V8l-5-5H8v14Zm-4 6q-.825 0-1.413-.588T2 21V8q0-.425.288-.713T3 7q.425 0 .713.288T4 8v13h10q.425 0 .713.288T15 22q0 .425-.288.713T14 23H4Zm4-6V3v14Z"
              />
            </svg>
          </span>
          รายการโพย
        </NuxtLink>
        <NuxtLink
          activeClass="font-semibold text-green-800"
          to="/results"
          class="mx-2 flex lg:flex-col xl:flex-row items-center"
        >
          <span class="">
            <svg
              class="pr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M17 21q-.425 0-.713-.288T16 20v-7.95q0-.425.288-.713T17 11.05h4q.425 0 .713.288t.287.712V20q0 .425-.288.713T21 21h-4Zm0-2h4v-5.95h-4V19Zm-9 2v-2h2v-2H4q-.825 0-1.413-.588T2 15V5q0-.825.588-1.413T4 3h14q.825 0 1.413.588T20 5v4.05h-2V5H4v10h10v2h-2v2h2v2H8Zm.7-7.25L11 12l2.3 1.75l-.85-2.85l2.3-1.85H11.9l-.9-2.8l-.9 2.8H7.25l2.3 1.85l-.85 2.85ZM11 10Z"
              />
            </svg>
          </span>
          ผลรางวัล
        </NuxtLink>
        <NuxtLink
          activeClass="font-semibold text-green-800"
          to="/transfer"
          class="mx-2 flex lg:flex-col xl:flex-row items-center"
          v-if="userStore.user.banks.length > 0"
        >
          <span class="">
            <svg
              class="pr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="m7 20l-4-4l4-4l1.425 1.4l-1.6 1.6H11v2H6.825L8.4 18.6L7 20Zm7-3q-.425 0-.713-.288T13 16q0-.425.288-.713T14 15q.425 0 .713.288T15 16q0 .425-.288.713T14 17Zm4 0q-.425 0-.713-.288T17 16q0-.425.288-.713T18 15q.425 0 .713.288T19 16q0 .425-.288.713T18 17Zm-1-5l-1.425-1.4l1.6-1.6H13V7h4.175L15.6 5.4L17 4l4 4l-4 4ZM6 9q-.425 0-.713-.288T5 8q0-.425.288-.713T6 7q.425 0 .713.288T7 8q0 .425-.288.713T6 9Zm4 0q-.425 0-.713-.288T9 8q0-.425.288-.713T10 7q.425 0 .713.288T11 8q0 .425-.288.713T10 9Z"
              />
            </svg>
          </span>
          ฝาก/ถอน
        </NuxtLink>
        <a
          activeClass="font-semibold text-green-800"
          href="/transfer"
          class="mx-2 flex lg:flex-col xl:flex-row items-center"
          v-else
        >
          <span class="">
            <svg
              class="pr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="m7 20l-4-4l4-4l1.425 1.4l-1.6 1.6H11v2H6.825L8.4 18.6L7 20Zm7-3q-.425 0-.713-.288T13 16q0-.425.288-.713T14 15q.425 0 .713.288T15 16q0 .425-.288.713T14 17Zm4 0q-.425 0-.713-.288T17 16q0-.425.288-.713T18 15q.425 0 .713.288T19 16q0 .425-.288.713T18 17Zm-1-5l-1.425-1.4l1.6-1.6H13V7h4.175L15.6 5.4L17 4l4 4l-4 4ZM6 9q-.425 0-.713-.288T5 8q0-.425.288-.713T6 7q.425 0 .713.288T7 8q0 .425-.288.713T6 9Zm4 0q-.425 0-.713-.288T9 8q0-.425.288-.713T10 7q.425 0 .713.288T11 8q0 .425-.288.713T10 9Z"
              />
            </svg>
          </span>
          ฝาก/ถอน
        </a>

        <NuxtLink
          activeClass="font-semibold text-green-800"
          to="/credits"
          class="mx-2 flex lg:flex-col xl:flex-row items-center"
        >
          <span class="">
            <svg
              class="pr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M2 21v-2h20v2H2Zm1-3v-7h3v7H3Zm5 0V6h3v12H8Zm5 0V9h3v9h-3Zm5 0V3h3v15h-3Z"
              />
            </svg>
          </span>
          รายงานเครดิต
        </NuxtLink>
        <NuxtLink
          activeClass="font-semibold text-green-800"
          to="/affiliate"
          class="mx-2 flex lg:flex-col xl:flex-row items-center"
        >
          <span class="">
            <svg
              class="pr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M.95 13.3q-.45-.9-.7-1.825T0 9.6q0-2.8 1.9-4.7T6.6 3q1.575 0 3 .663T12 5.5q.975-1.175 2.4-1.837t3-.663q2.8 0 4.7 1.9T24 9.6q0 .95-.25 1.875t-.7 1.825q-.275-.475-.65-.85t-.875-.6q.225-.575.35-1.125T22 9.6q0-1.95-1.325-3.275T17.4 5q-2.025 0-3.112 1.113T12 8.6q-1.2-1.4-2.288-2.5T6.6 5Q4.65 5 3.325 6.325T2 9.6q0 .575.125 1.125t.35 1.125q-.5.225-.875.6t-.65.85ZM0 22v-1.575q0-1.1 1.113-1.763T4 18q.325 0 .625.013t.575.062q-.35.5-.525 1.075T4.5 20.375V22H0Zm6 0v-1.625q0-1.625 1.663-2.625t4.337-1q2.7 0 4.35 1T18 20.375V22H6Zm13.5 0v-1.625q0-.65-.163-1.225t-.487-1.075q.275-.05.563-.063T20 18q1.8 0 2.9.663t1.1 1.762V22h-4.5ZM4 17q-.825 0-1.413-.588T2 15q0-.85.588-1.425T4 13q.85 0 1.425.575T6 15q0 .825-.575 1.413T4 17Zm16 0q-.825 0-1.413-.588T18 15q0-.85.588-1.425T20 13q.85 0 1.425.575T22 15q0 .825-.575 1.413T20 17Zm-8-1q-1.25 0-2.125-.875T9 13q0-1.275.875-2.138T12 10q1.275 0 2.138.863T15 13q0 1.25-.863 2.125T12 16Z"
              />
            </svg>
          </span>
          ระบบแนะนำ
        </NuxtLink>
        <NuxtLink
          activeClass="font-semibold text-green-800"
          to="/menu"
          class="mx-2 flex lg:flex-col xl:flex-row items-center"
        >
          <span class="">
            <svg
              class="pr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M5.85 17.1q1.275-.975 2.85-1.538T12 15q1.725 0 3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.337T4 12q0 1.475.488 2.775T5.85 17.1ZM12 13q-1.475 0-2.488-1.012T8.5 9.5q0-1.475 1.012-2.488T12 6q1.475 0 2.488 1.012T15.5 9.5q0 1.475-1.012 2.488T12 13Zm0 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q1.325 0 2.5-.388t2.15-1.112q-.975-.725-2.15-1.113T12 17q-1.325 0-2.5.388T7.35 18.5q.975.725 2.15 1.113T12 20Zm0-9q.65 0 1.075-.425T13.5 9.5q0-.65-.425-1.075T12 8q-.65 0-1.075.425T10.5 9.5q0 .65.425 1.075T12 11Zm0-1.5Zm0 9Z"
              />
            </svg>
          </span>
          เมนู
        </NuxtLink>
      </div>
      <div
        class="col-span-12 md:col-span-12 lg:col-span-2 xl:col-span-2 hidden lg:flex justify-end items-center px-0 mx-0"
      >
        <div class="flex w-full">
          <span class="flex justify-end align-center text-base w-full">
            <NuxtLink to="/contact" class="flex">
              <PhoneIcon class="h-5 w-5 flex-none mx-2" aria-hidden="true" />
              ติดต่อเจ้าหน้าที่
            </NuxtLink>
          </span>
        </div>
      </div>

      <div class="col-span-12 text-sm flex justify-center px-1">
        <div class="flex w-full">
          <span class="flex justify-start align-center text-base">
            <NuxtLink to="/profile" class="flex">
              <UserIcon class="h-5 w-5 flex-none" aria-hidden="true" />

              :{{ userStore.user.username }}
            </NuxtLink>
          </span>
        </div>
        <div class="flex w-full">
          <span class="flex justify-end align-center text-base w-full">
            <img
              class="w-[18px] h-[18px]"
              src="~assets/images/icon/thai-baht.webp"
              alt="เครดิต"
            />:{{ showCredit(Number(currentCredit)) }}
          </span>
        </div>
      </div>
    </div>
    <!-- <GlobalsHeaderPopup :open="true"></GlobalsHeaderPopup> -->
    <!-- {{ filterPopup() }} -->
    <!-- <TransitionRoot as="template" :show="openPopup">
      <Dialog class="relative z-10" @close="openPopup = false">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-green-100/75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            class="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
              >
                <div class="flex items-start justify-center gap-2">
                  <div class="flex shrink-0 w-2/12 sm:w-1/12">
                    <div
                      class="items-center justify-center rounded-full bg-green-100 bg-green-100 h-10 w-10 flex"
                    >
                      <BellIcon
                        class="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div class="text-left mt-2 sm:text-left w-10/12 sm:w-11/12">
                    <DialogTitle
                      as="h3"
                      class="text-base font-semibold text-gray-900"
                      >{{ modalTitle }}</DialogTitle
                    >
                    <div class="mt-2 flex w-100">
                      <p class="text-sm text-gray-500" v-html="modalDetail"></p>
                    </div>
                  </div>
                </div>
                <div class="flex items-start justify-end gap-2">
                  <div class="text-left mt-2 sm:text-left w-10/12 sm:w-11/12">
                    <div
                      class="mt-5 sm:mt-4 flex items-center justify-between gap-2"
                    >
                      <div class="flex gap-3">
                        <div class="flex h-6 shrink-0 items-center">
                          <div class="group grid size-4 grid-cols-1">
                            <input
                              v-model="diablechecked"
                              id="disablePopup"
                              aria-describedby="disablePopup-description"
                              name="disablePopup"
                              type="checkbox"
                              class="w-4 h-4"
                            />
                          </div>
                        </div>
                        <div class="text-sm/6">
                          <label
                            for="disablePopup"
                            class="font-medium text-gray-500"
                            >ไม่แสดงข้อความนี้อีก</label
                          >
                        </div>
                      </div>

                      <button
                        type="button"
                        class="mt-3 inline-flex w-full justify-center rounded-md bg-red-100 px-3 py-2 text-sm font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50 sm:mt-0 sm:w-auto px-5"
                        @click="openPopup = false"
                      >
                        ปิด
                      </button>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot> -->
  </div>
</template>
<script setup>
const config = useRuntimeConfig();
const base_api = config.public.apiUri;
const userStore = useUserStore();
const modalTitle = ref(null);
const modalDetail = ref(null);
const diablechecked = ref(false);
import { ref } from "vue";
import { set, useArrayFilter } from "@vueuse/core";

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  CheckIcon,
  UserIcon,
  PhoneIcon,
  BellIcon,
} from "@heroicons/vue/24/outline";
const openPopup = ref(true);
const apiUrlCurrentCredit = ref(`${base_api}/user/credit/current`);
const { data: currentCredit } = await useFetch(apiUrlCurrentCredit, {
  headers: { Authorization: `Bearer ${userStore.token}` },
});

const {
  data: news,
  error,
  pending,
} = await useFetch(
  `${base_api}/user/announce/${userStore.user.network.agent_id}`,
  {
    key: "fetchAnnounce",
    server: true,
    headers: { Authorization: `Bearer ${userStore.token}` },
    method: "get",
  }
);

async function filterHotnews() {
  var result = await useArrayFilter(
    news.value.docs,
    (i) => i.type === "hotnews"
  );

  if (result.value.length > 0) {
    return result.value[0].detail;
  } else {
    return false;
  }
}
var hotnews = await filterHotnews();
async function filterPopup() {
  const result = await useArrayFilter(
    news.value.docs,
    (i) => i.type === "popup"
  );
  if (result.value.length > 0) {
    set(modalTitle, result.value[0].title);
    set(modalDetail, result.value[0].detail);
    set(openPopup, true);
  } else {
    set(openPopup, false);
  }
}
var popup = await filterPopup();
// function closePopup() {
//   set(openPopup, false);
// }
async function closePopup() {
  set(openPopup, false);
}
const { showCredit } = useUtils();
const user = userStore.user;
const signOut = async () => {
  await userStore.signOut();
  useRouter().go(0);
};
// filterPopup();
</script>
<style scoped>
@media (min-width: 1024px) {
  .lg-text-left {
    text-align: left !important;
  }
}
a.nuxt-link-active {
  font-weight: bold;
}
/* exact link will show the primary color for only the exact matching link */
a.nuxt-link-exact-active {
  color: #00c58e;
}
.bg-yellow-500 {
  background: url("~/assets/images/bg-pattern/white.webp") !important;
  background-repeat: repeat-x;
  background-size: 250px !important;
}
</style>
